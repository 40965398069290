import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../../../components/carousel'
import Layout from '../../../../components/layout'
import SEO from '../../../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Epicore MSR Painted' },
    { img: data.i2, caption: 'Epicore MSR Painted' },
    { img: data.i3, caption: 'Painted 20 Gage Epicore MSR Deck' },
    { img: data.i4, caption: 'Painted 20 Gage Epicore MSR Deck' },
    { img: data.i5, caption: 'Painted 20 ga Epicore MSR' },
    { img: data.i6, caption: 'Epicore MSR 20 ga Painted' },
    { img: data.i7, caption: 'Epicore MSR 20 ga Painted' },
    { img: data.i8, caption: 'Epicore MSR 20 ga Painted' },
    { img: data.i9, caption: 'Epicore MSR 20 ga Painted' },
    { img: data.i10, caption: 'Epicore MSR Painted' },
    { img: data.i11, caption: 'Epicore MSR 20 ga Painted' },
  ];

  return (
    <Layout>
      <SEO title="System Components: EPICORE MSR Floor System: Ceiling Treatment - Painting" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components: EPICORE MSR Floor System</h2>
        <h1 className='title title--xxxl color-primary'>Ceiling Treatment - Painting</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>A very economical ceiling finish can be to simply paint the underside of the
          Epicore MSR deck. Since the Epicore MSR has closed flutes, there will be a seam
          every 8” on center creating a linear planking effect (similar to a tongue & groove
          1 X 8 wood plank ceiling).  The Epicore MSR deck should be thoroughly cleaned with
          a pre-paint cleaner to remove any oils. A metal prep primer spray (compatible
          with galvanized metal) is field applied, followed by the finish coat of paint.
          The finish should be either a flat non-glossy paint or a “popcorn” texture.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/01-CEILI110_(40010).png" }) {
            ...GalleryCarouselImageFragment },
        i2: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/02-CEILI090_(40007).png" }) {
            ...GalleryCarouselImageFragment },
        i3: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/03-CEILI570_(42004).png" }) {
            ...GalleryCarouselImageFragment },
        i4: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/04-CEILI580_(42005).png" }) {
            ...GalleryCarouselImageFragment },
        i5: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/05-Mason110_(41957).png" }) {
            ...GalleryCarouselImageFragment },
        i6: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/06-CEILI030_(41626).png" }) {
            ...GalleryCarouselImageFragment },
        i7: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/07-CEILI510_(40655).png" }) {
            ...GalleryCarouselImageFragment },
        i8: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/08-CEILI530_(40657).png" }) {
            ...GalleryCarouselImageFragment },
        i9: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/09-Moreh010_(34317).png" }) {
            ...GalleryCarouselImageFragment },
        i10: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/10-CEILI320_(34326).png" }) {
            ...GalleryCarouselImageFragment },
        i11: file(relativePath: { eq: "system-components/epicore-msr-floor-system/ceiling-treatment/painting/gallery/11-CEILI540_(40658).png" }) {
            ...GalleryCarouselImageFragment },
    }
`
